#home {
  background: url('../../assets/images/santarock2.jpg');
  background-size: cover;
  min-height: 100vh;
  max-width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;

  .background {
    background-color: rgba(49, 49, 49, 0.35);
    min-height: 100vh;

    :last-child {
      margin-bottom: 0;
    }
  }

  .header {
    display: flex;
    padding: 0 5rem;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    .header-img {
      padding: 0;
      border-radius: 30%;
      width: 13rem;
      height: 15rem;

      @media screen and (max-width: 900px) {
        height: 10rem;
        width: 8rem;
      }

      @media screen and (max-width: 450px) {
        height: 3rem;
        width: 2rem;
      }
    }

    .header-text {
      font-size: 2.15rem;
      font-weight: 800;
      text-align: center;
      text-justify: inter-word;
      color: var(--gold-color);
      padding: 1rem;
      text-shadow: -2px 0 black;
      line-height: 1.5;

      @media screen and (max-width: 900px) {
        font-size: 1.6rem;
      }

      @media screen and (max-width: 450px) {
        font-size: 1.25rem;
      }

      a {
        color: var(--gold-color);
      }
    }
  }

  img {
    padding-top: 10rem;
    justify-content: center;
    height: 50vh;
  }

  .info-text {
    font-size: 2.15rem;
    font-weight: 800;
    text-align: center;
    text-justify: inter-word;
    color: var(--gold-color);
    padding: 1rem;
    text-shadow: -2px 0 black;
    line-height: 1.5;
    margin: 20px 15rem 15px;

    @media screen and (max-width: 900px) {
      font-size: 1.6rem;
      margin: 40px 70px 10px;
    }

    @media screen and (max-width: 450px) {
      font-size: 1.25rem;
      margin: 20px 10px 10px;
    }

    a {
      font-size: 2rem;
      font-weight: 300;
      color: var(--white-color);
      text-shadow: -3px 0 var(--darkRed-color);
      text-decoration: none;

      @media screen and (max-width: 900px) {
        font-size: 1.6rem;
      }

      @media screen and (max-width: 450px) {
        font-size: 1.25rem;
      }

      &:hover {
        background-color: var(--primary-color);
        border-radius: 5px;
      }
    }

    .link-text {
      border: 3px solid var(--darkGreen-color);
      background-color: var(--darkRed-color);
    }
  }

  .schedule {
    font-size: 1.3rem;
    text-align: center;
    color: var(--gold-color);
    width: 90%;
    margin: auto;
    ul {
      list-style: none;
    }

    @media screen and (max-width: 900px) {
      font-size: 1.1rem;
    }

    @media screen and (max-width: 450px) {
      font-size: 1rem;
    }
  }

  .green-card {
    color: var(--white-color);
    font-weight: 400;
    background-color: var(--primary-color);
    border: 25px;
    border-style: double;
    border-color: var(--white-color);
    padding: 1rem;
    text-shadow: -3px 0 var(--darkRed-color);
  }

  .red-card {
    color: var(--white-color);
    font-weight: 400;
    background-color: var(--secondary-color);
    border: 25px;
    border-style: double;
    border-color: var(--white-color);
    padding: 1rem;
    text-shadow: -3px 0 var(--darkGreen-color);
  }

  h3 {
    font-family: 'Mountains of Christmas', cursive;
    margin: 0.75rem;
    padding: 0.25rem 1rem;
    color: var(--white-color);
    text-shadow: -5px 0 var(--darkRed-color);
    border-bottom: 5px;
    font-size: 4rem;
    font-weight: 600;
    text-align: center;
  }

  .funky {
    color: var(--white-color);
    text-shadow: -5px 0 var(--darkGreen-color);
    border-bottom: 5px;
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
  }

  .musicians-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 3rem;

    li {
      font-family: 'Mountains of Christmas', cursive;
      font-size: 3rem;
      list-style: none;
      padding: 1rem 2rem;
      margin: 2rem;
      color: #ffffff;
      background-color: var(--primary-color);
      border-radius: 10px;

      &:nth-child(odd) {
        background-color: var(--secondary-color);
      }

      @media screen and (max-width: 900px) {
        font-size: 1.6rem;
        margin: 40px 70px 10px;
      }

      @media screen and (max-width: 450px) {
        font-size: 1.25rem;
        margin: 20px 10px 10px;
      }
    }
  }

  .sponsor-links {
    display: flex;
    justify-content: center;

    a {
      font-size: 1.9rem;
      font-family: 'Mountains of Christmas', cursive;
      font-weight: bold;
      text-decoration: none;
      background-color: var(--white-color);
      color: var(--primary-color);
      text-shadow: -3px 0 black;
      margin: 2rem;
      padding: 1.5rem;
      border-radius: 50%;
      width: 25rem;
      text-align: center;

      &:hover {
        color: var(--white-color);
        background-color: var(--primary-color);
      }
      @media screen and (max-width: 800px) {
        width: 80%;
        height: 9rem;
        border-radius: 10px;
      }
    }
    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
  }
}
