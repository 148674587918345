@import url('https://fonts.googleapis.com/css2?family=Mountains+of+Christmas:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Domine:wght@500&display=swap');

:root {
  --font-base: "Domine", serif;

  --primary-color: #0F8A5F;
  --secondary-color: #CC231E;
  --green-color: #34A65F;
  --darkGreen-color: #146152;
  --darkRed-color: #832831;
  --lightRed-color: #F5624D;
  --black-color: #030303;
  --white-color: #E9E3C9;
  --darkBlue-color: #235E6F;
  --gold-color:#ddc06f;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
