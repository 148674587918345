.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0rem 1.25rem;
}

.icon {
  font-size: 3rem;
  color: var(--white-color);
  padding: 8px;
  background-color: var(--secondary-color);
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
  outline-style: solid;
  outline-color: var(--gray-color);
}